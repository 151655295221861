import { styled } from "styled-components";

import {
  AvatarButton,
  Icon,
  Menu as MenuBase,
  Stack,
} from "@fidelix/fx-miranda";

import { useCurrentUserQuery } from "~user/user.queries";

export function NavMenu() {
  const { data: user } = useCurrentUserQuery();

  return (
    <Menu
      trigger={
        <AvatarButton
          name={user?.fullName || ""}
          data-test-id="nav-menu-button"
        />
      }
    >
      <Menu.Item href="/logout" data-test-id="nav-menu-item-logout">
        <Stack axis="x" spacing="xsmall" align="center">
          <Icon icon="logOut" size={18} />
          <span>Logout</span>
        </Stack>
      </Menu.Item>
    </Menu>
  );
}

const Menu = styled(MenuBase)`
  min-width: 250px;
`;
